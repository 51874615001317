<template>
  <div class="news">
    <h3 v-if="data.title" v-html="data.title"></h3>
    <p
      v-for="(summary, index) in data.summary"
      :key="index"
      v-html="summary"
    ></p>
    <p class="quote" v-if="data.quote" v-html="data.quote"></p>
    <p class="signature">{{ data.signature }}</p>

    <button v-if="data.cta" class="default" @click="openUrl(data.cta)">
      Learn more
    </button>
  </div>
</template>

<script>
export default {
  name: "Tep-News",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openUrl(url) {
      //   window.location = url;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  position: relative;
  width: 100%;
  overflow: hidden;
  // box-shadow: 0 5px 5px -5px gray;

  .quote {
    font-style: italic;
  }
  .signature {
    text-align: right;
  }
}
</style>
