<template>
  <div class="content news">
    <banner title="News" :image="3"> </banner>
    <div class="section" v-for="(news, index) in data" :key="index">
      <div class="content_wrapper">
        <news :data="news"></news>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import News from "@/components/News.vue";
import NewsData from "@/news_data.json";
export default {
  name: "News-view",
  components: {
    Banner,
    News,
  },
  data() {
    return {
      data: NewsData,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
